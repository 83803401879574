<template>
  <div>
    <div class="grid">
      <div class="col-12">
        <div class="col-12 xl:col-12">
          <div class="card">
            <h5>Relatório Gedi Importado</h5>
            
            <DataTable
              :value="relatorioGedi"
              :rows="10"
              :paginator="true"
              csvSeparator=";"
              :globalFilterFields="cabecalhoGedi.map(col => col.field)"
              ref="dtTracking"
              responsiveLayout="scroll"
            >
              <template #header>
                <div class="flex justify-content-end">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar" />
                  </span>
                  <Button
                    label="Exportar Excel"
                    icon="pi pi-upload"
                    class="p-button-success ml-5"
                    @click="exportCSVGedi($event)"
                  />
                </div>
              </template>

              <Column
                v-for="col in cabecalhoGedi"
                :key="col.field"
                :field="col.field"
                :header="col.header"
                style="white-space: nowrap"
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      id_fornecedor: '',
      mes: '',
      ano: '',
      mostrarPlanilhaModal: false,
      planilhas: [],
      anos: [
        { label: "", value: "" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },
        { label: "2024", value: "2024" },
        { label: "2025", value: "2025" }
      ],
      meses: [
        { label: "", value: "" },
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" },
      ],
      cabecalho: [
        { header: "Data Upload", field: 'created_at' },
        { header: "Fornecedor", field: 'razao_social' },
        { header: "Número do Lote", field: 'planilha_id' },
        { header: "Responsável", field: 'usuario_responsavel' },
        { header: "End ID Enviados", field: 'total_dados' },
        { header: "Dados", field: 'dados' },
      ],
      relatorioGedi: [],
      cabecalhoGedi: [],
      empresas: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      empresa: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      fornecedores: [],
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
      dadosPlanilhasImportadas: [],
      tipoPlanilha: "",
      loading: false
    };
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.listarRelatorioGedi();
  },
  methods: {
    listarRelatorioGedi() {
      this.loading = true;
      this.axios
        .get("/relatorios/lista-relatorio-gedi")
        .then((response) => {
          this.relatorioGedi = response.data.dados || [];
          if (this.relatorioGedi.length > 0) {
            this.cabecalhoGedi = Object.keys(this.relatorioGedi[0]).map((chave) => {
              return {
                field: chave,
                header: chave.replace(/_/g, ' ').toUpperCase()
              };
            });
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Não foi possível carregar o Relatório Gedi. Contate o T.I.",
            life: 3000,
          });
        });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    exportCSV2() {
      this.$refs.rf.exportCSV();
    },

    // Exportar dados do Relatório Tracking
    exportCSVGedi() {
      this.$refs.dtTracking.exportCSV();
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
